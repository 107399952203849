<template>
  <div class="wrapper">
    <transition name="fade">
      <screenshot-modal v-if="showingScreenshotModal" />
    </transition>

    <!-- main -->
    <div class="main">
      <!-- loading -->
      <div
        v-if="loading"
        class="spinner-wrapper xl:flex-1 xl:flex xl:justify-center"
      >
        <c-spinner />
      </div>

      <template v-else>
        <!-- navigation toggle -->
        <div
          class="xl:hidden px-8 py-4 bg-white dark:bg-gray-900 border-b dark:border-gray-700"
        >
          <button
            class="navigation-toggle"
            :class="{
              'mr-4 flex items-center': true,
              'self-center text-gray-500 dark:text-gray-400 mr-4': true,
              'xl:hidden': true,
            }"
            title="Toogle navigation"
            @click.prevent="appStore.toggleNavigation()"
          >
            <c-icon :size="6" :icon="icon" />
          </button>
        </div>

        <!-- navigation -->
        <div
          class="aside"
          :class="{
            hidden: !appStore.showingNavigation,
            'bg-gray-100 dark:bg-gray-800': true,
            'z-30 inset-0 xl:left-auto xl:right-auto': appStore.showingNavigation,
            'fixed bottom-0 top-0': true,
            'xl:flex xl:flex-col xl:w-80 xl:min-w-0 overflow-y-auto': true,
          }"
        >
          <div class="relative px-8 pt-0 pb-16">
            <div class="sticky top-0">
              <div class="bg-gray-100 dark:bg-gray-800">
                <div class="py-4">
                  <button
                    ref="navigationToggle"
                    class="navigation-toggle"
                    :class="{
                      'mr-4 flex items-center': true,
                      'self-center text-gray-500 dark:text-gray-400 mr-4': true,
                      'xl:hidden': true,
                    }"
                    title="Toogle navigation"
                    @click.prevent="appStore.toggleNavigation()"
                  >
                    <c-icon :size="6" :icon="icon" />
                  </button>
                </div>

                <header class="relative z-20">
                  <div>
                    <craft-version-switcher />
                  </div>
                </header>

                <plugin-search class="mt-6" />
              </div>

              <div
                class="h-6 bg-gradient-to-b from-gray-100 dark:from-gray-800"
              />
            </div>

            <div class="navigation">
              <navigation-items />
            </div>
          </div>
        </div>

        <!-- view -->
        <div
          id="view"
          ref="view"
          class="view py-10 xl:pl-80 bg-white dark:bg-gray-900 min-h-screen"
        >
          <div class="max-w-screen-xl w-full mx-auto">
            <seo-meta />

            <slot />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {useRoute} from 'vue-router';
  import {onMounted, onUnmounted} from 'vue';
  import ScreenshotModal from '../components/ScreenshotModal.vue';
  import SeoMeta from '../components/SeoMeta.vue';
  import PluginSearch from '../components/PluginSearch.vue';
  import NavigationItems from '../components/NavigationItems.vue';
  import CraftVersionSwitcher from '../components/CraftVersionSwitcher.vue';
  import {useEnv} from '../helpers/env';
  import {computed, ref, useAppStore, useHead} from '#imports';

  const route = useRoute();
  const {appUrl} = useEnv();

  const appStore = useAppStore();

  const title = computed(() =>
    appStore.pageMeta?.title ? appStore.pageMeta.title : 'Default Title',
  );
  const description = computed(() =>
    appStore.pageMeta?.description
      ? appStore.pageMeta.description
      : 'Default Description',
  );

  useHead({
    title,
    meta: [{hid: 'description', name: 'description', content: description}],
    link: [
      {rel: 'canonical', href: (appUrl || '') + route.fullPath},
      {
        rel: 'alternate',
        type: 'application/atom+xml',
        title: 'New Plugins',
        href: 'https://feeds.craftcms.com/new.atom',
      },
      {
        rel: 'alternate',
        type: 'application/atom+xml',
        title: 'Plugin Releases',
        href: 'https://feeds.craftcms.com/releases.atom',
      },
      {
        rel: 'alternate',
        type: 'application/atom+xml',
        title: 'Critical Plugin Releases',
        href: 'https://feeds.craftcms.com/critical.atom',
      },
    ],
  });

  const loading = ref(false);

  const showingScreenshotModal = computed(
    () => appStore.showingScreenshotModal,
  );

  const icon = computed(() => {
    if (appStore.showingNavigation) {
      return 'x';
    }

    return 'menu';
  });

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && appStore.showingNavigation) {
      appStore.toggleNavigation();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', onKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', onKeyDown);
  });
</script>

<style lang="scss">
  .navigation-main {
    -webkit-overflow-scrolling: touch;
  }

  .craft-version-dropdown {
    select {
      @apply py-1 pr-8;
    }
  }
</style>
