<template>
  <swiper-container
    :navigation="true"
    :pagination="{
      clickable: true,
    }"
    :initial-slide="initialSlide"
    :keyboard="true"
    :loop="false"
    :zoom="true"
  >
    <template v-for="(imageUrl, key) in images" :key="key">
      <swiper-slide>
        <img
          :alt="'Plugin screenshot ' + (key + 1) + '/' + images.length"
          :src="imageUrl"
        />
      </swiper-slide>
    </template>
  </swiper-container>
</template>

<script setup lang="ts">
  import {register} from 'swiper/element/bundle';
  import {toRefs} from '#imports';

  register();

  const props = withDefaults(
    defineProps<{
      images?: any[];
      initialSlide?: number;
    }>(),
    {
      images: () => [],
      initialSlide: 0,
    },
  );

  const {images, initialSlide} = toRefs(props);
</script>

<style lang="scss">
  /* Container */
  swiper-container {
    @apply absolute inset-0;
  }

  /*Slide*/
  swiper-slide {
    @apply w-full h-full flex text-center justify-center items-center;

    img {
      @apply max-w-full max-h-full min-h-screen object-contain;
      min-width: 100vw;
    }
  }
  @media (min-width: 700px) {
    swiper-slide img {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  @media (min-height: 700px) {
    swiper-slide img {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  /* Pagination */
  swiper-container::part(button-prev),
  swiper-container::part(button-next) {
    @apply px-2 py-8 rounded-md;
    @apply flex justify-center items-center w-auto;
    @apply text-gray-700 dark:text-gray-200 text-opacity-75 hover:text-opacity-100;
    @apply bg-gray-400/10 hover:bg-gray-400/20 dark:bg-gray-500/10 dark:hover:bg-gray-500/20;
  }

  swiper-container::part(pagination) {
    @apply bottom-[40px] left-1/2 transform -translate-x-1/2;
    @apply max-w-xs mx-auto;
    @apply absolute flex flex-1 bg-gray-200 dark:bg-gray-700 p-0 rounded-full;
  }

  swiper-container::part(bullet),
  swiper-container::part(bullet-active) {
    @apply flex-1 rounded-full bg-gray-200 dark:bg-gray-700 border border-red-500;
    height: 8px;
    margin: 0;
  }

  swiper-container::part(bullet-active) {
    @apply bg-gray-900 dark:bg-gray-100;
  }
</style>
