<template>
  <form id="search-form" class="relative flex-1" @submit.prevent="submitSearch">
    <div
      class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
    >
      <c-icon
        class="text-gray-500 absolute text-gray-500 dark:text-gray-400 w-5 h-5"
        icon="search"
      />
    </div>

    <c-textbox
      id="searchQuery"
      v-model="searchQuery"
      autocomplete="off"
      class="text w-full pl-12 placeholder:text-gray-500 dark:placeholder:text-gray-400"
      name="searchQuery"
      placeholder="Search plugins"
      type="text"
    />

    <div
      class="clear"
      :class="{hidden: searchQuery.length == 0}"
      title="Clear"
      @click="searchQuery = ''"
    />
  </form>
</template>

<script setup lang="ts">
  import {useRoute, useRouter} from 'vue-router';
  import CTextbox from './ui/CTextbox.vue';
  import {computed, useAppStore} from '#imports';

  const appStore = useAppStore();
  const route = useRoute();
  const router = useRouter();

  // Computed
  const getCmsConstraintQuery = computed(() => appStore.getCmsConstraintQuery);
  const searchQuery = computed({
    get: () => {
      return appStore.searchQuery;
    },
    set: (value) => {
      appStore.updateSearchQuery(value);
    },
  });

  // Methods
  const submitSearch = () => {
    if (!searchQuery.value) {
      return false;
    }

    const query = {
      q: searchQuery.value,
      tab: route.query.tab ? route.query.tab : 'plugins',
      ...getCmsConstraintQuery.value(),
    };

    router.push({
      path: '/search',
      query,
    });
  };
</script>
