<template>
  <div
    v-if="pageMeta && showSeoMeta"
    class="seo-meta bg-blue-100 text-blue-800 p-4 rounded-md mx-8 mb-10"
  >
    <ul>
      <li class="mb-2"><strong>Title:</strong> {{ pageMeta.title }}</li>
      <li><strong>Description:</strong> {{ pageMeta.description }}</li>
    </ul>
  </div>
</template>

<script setup lang="ts">
  import {useEnv} from '~/helpers/env';
  import {computed, useAppStore} from '#imports';

  const {showSeoMeta} = useEnv();
  const appStore = useAppStore();

  const pageMeta = computed(() => appStore.pageMeta);
</script>
