<template>
  <div
    class="screenshot-modal fixed inset-0 bg-gray-100 dark:bg-gray-900 z-50 overflow-hidden"
  >
    <a
      class="close text-center absolute top-0 left-0 z-30 rounded px-3 py-3 mt-4 ml-4 leading-none"
      :class="{
        'text-gray-700 dark:text-gray-200 text-opacity-75 hover:text-opacity-100': true,
        'bg-gray-400/10 hover:bg-gray-400/20 dark:bg-gray-500/10 dark:hover:bg-gray-500/20': true,
      }"
      title="Close"
      @click="close"
    >
      <c-icon icon="x" class="w-8 h-8" :size="null" />
    </a>

    <carousel
      v-if="screenshotModalImages"
      :images="screenshotModalImages"
      :initial-slide="screenshotModalImageKey"
    />
  </div>
</template>

<script setup lang="ts">
  import Carousel from './Carousel';
  import {computed, onMounted, onUnmounted, useAppStore} from '#imports';

  const appStore = useAppStore();

  // Computed
  const screenshotModalImages = computed(() => appStore.screenshotModalImages);
  const screenshotModalImageKey = computed(
    () => appStore.screenshotModalImageKey,
  );

  // Methods
  const close = () => {
    appStore.toggleScreenshotModal();
  };

  const handleEscapeKey = (e) => {
    if (e.keyCode === 27) {
      close();
    }
  };

  // Mounted
  onMounted(() => {
    window.addEventListener('keydown', handleEscapeKey);
  });

  // Unmounted
  onUnmounted(() => {
    window.removeEventListener('keydown', handleEscapeKey);
  });
</script>

<style lang="scss">
  .screenshot-modal {
    .carousel {
      @apply absolute flex inset-0;
    }
  }
</style>
