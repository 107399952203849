<template>
  <div class="navigation-items">
    <ul>
      <li>
        <c-link
          to="/"
          title="Discover Craft CMS plugins"
          :class="{
            'nuxt-link-exact-active nuxt-link-active': $route.path === '/',
          }"
        >
          <c-icon icon="star" class="w-6 h-6 text-blue-500" />
          <span>Discover</span>
        </c-link>
      </li>
      <li v-for="(category, categoryKey) in categories" :key="categoryKey">
        <c-link
          :to="'/categories/' + category.slug"
          :title="category.title + ' plugins for Craft CMS'"
          :class="{
            'nuxt-link-exact-active nuxt-link-active':
              $route.path === '/categories/' + category.slug,
          }"
        >
          <img
            :alt="category.title + ' icon'"
            :src="category.iconUrl"
            width="24"
            height="24"
            class="inline"
          />
          {{ category.title }}
        </c-link>
      </li>
    </ul>

    <div class="border-t dark:border-gray-700 mt-8 py-8">
      <ul>
        <li>
          <a href="https://craftcms.com/" title="Craft CMS">
            <c-icon icon="world" class="w-6 h-6" />
            craftcms.com
          </a>
        </li>
        <li>
          <a href="https://console.craftcms.com/" title="Craft Console">
            <c-icon icon="world" class="w-6 h-6" />
            Craft Console
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {computed, usePluginStoreStore} from '#imports';

  const pluginStoreStore = usePluginStoreStore();
  const categories = computed(() => pluginStoreStore.categories);
</script>

<style>
  .navigation-items {
    h3 {
      @apply mb-2 mt-8 uppercase text-sm text-gray-500;

      &.first {
        @apply mt-0;
      }
    }

    ul {
      @apply space-y-0.5 text-base;

      li {
        a {
          @apply block py-2 no-underline px-2 text-gray-600 dark:text-gray-400 flex items-center rounded-md;

          img,
          svg {
            @apply align-middle mr-4;
          }

          &:hover {
            @apply text-gray-800 dark:text-white;
            @apply bg-gray-200 dark:bg-gray-700 bg-opacity-50 dark:bg-opacity-50;
          }

          &.nuxt-link-exact-active {
            @apply font-medium;
            @apply bg-gray-200 dark:bg-gray-700;
            @apply text-blue-500 dark:text-blue-400;
          }
        }
      }
    }
  }
</style>
